var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view"},[_c('ais-configure',{attrs:{"hitsPerPage":5,"facetFilters":['title:' + _vm.title],"facets":['title'],"query":_vm.aisQuery}}),_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(searchState){return [_c('div',{staticClass:"artist-page__content"},[_c('div',{staticClass:"filter-icon__container"},[_c('a',{staticClass:"filter-link",class:{ active: _vm.showFilter },attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.onFilterClick.apply(null, arguments)}}},[_c('img',{staticClass:"filter-icon",attrs:{"src":_vm.filterIcon,"alt":"Filter"}})])]),_c('router-link',{staticClass:"back",attrs:{"to":"/"}},[_c('img',{staticClass:"desktop_form_close",attrs:{"src":require("@/assets/img/close.svg"),"alt":"close"}})]),_c('form',{staticClass:"group"},[_c('label',[_c('strong',[_vm._v("Title:")])]),_c('div',{ref:"headlineContainer",staticClass:"headline__container"},[_c('BaseHeadline',{ref:"headline",attrs:{"maxLength":"55","text":_vm.$route.params.title}}),(_vm.screen.width > 767)?_c('ais-search-box',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var refine = ref.refine;
return [_c('div',{staticClass:"filter-input__container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(currentRefinement),expression:"currentRefinement"}],staticClass:"filter-input",class:{ active: _vm.showFilter },attrs:{"type":"search","placeholder":"type to filter","id":"title_search"},domProps:{"value":(currentRefinement)},on:{"input":[function($event){if($event.target.composing){ return; }currentRefinement=$event.target.value},function($event){return refine($event.currentTarget.value)}]}})])]}}],null,true)}):_vm._e()],1)]),_c('div',{staticClass:"group"},[_c('label',{staticClass:"titles-headline__container"},[_c('strong',[_vm._v("Artists")]),_vm._v(" ("),_c('span',[_vm._v(_vm._s(_vm.getTitlesCount(searchState)))]),_vm._v("):")]),_c('div',{staticClass:"mobile-filter__container"},[_c('div',{staticClass:"mobile-filter__content"},_vm._l((_vm.$options.mobileFilterOptions),function(filter){return _c('div',{key:filter},[_c('a',{staticClass:"mobile-filter-category",class:{ active: filter === _vm.mobileFilterCategory },attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.setMobileFilterCategory(filter)}}},[_vm._v(" "+_vm._s(filter)+" ")])])}),0)])]),_c('div',[_c('ais-infinite-hits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var refineNext = ref.refineNext;
var isLastPage = ref.isLastPage;
return _c('div',{staticClass:"search-results"},[_c('div',{staticClass:"search-results__content"},[_c('ul',{staticClass:"title-hits"},[_vm._l((items),function(item){return _c('li',{key:item.objectID},[_c('router-link',{attrs:{"to":'/details/' + item.mtw_id}},[_vm._v(" "+_vm._s(item.artist_name)+" ")])],1)}),_c('LoadMore',{on:{"loadMore":function($event){!isLastPage && refineNext()}}})],2)])])}}],null,true)})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }